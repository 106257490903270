import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import CTAarrow from "../assets/svg/ubuntu-arrow-cta.svg"

const SuccessPage = () => (
  <Layout>
    <div id="success">
      <h1>Your message has been sent</h1>
      <p>We will contact you as soon as possible</p>
      <div className="cta-container">
        <Link to="/home"><CTAarrow className="clickable"/></Link>
        <Link to="/home">GO BACK</Link>
      </div>
    </div>
  </Layout>
)

export default SuccessPage
